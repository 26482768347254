.main-navbar {

    .disconnectbtn {
        float: right;

        button {
            border-radius: 15px;
background: #0E6AAD;
            display: flex;
            padding:16px 30px;
            justify-content: center;
            align-items: center;
            color: #E8F2FF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            border: none;
            outline: none;
            text-transform: capitalize;
            &:hover{
                color: #FFFFFF !important;
    background: #298AD0 !important;
            }
        }
    }

    .hjbdbsc {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.dfgtryrthvbdfg {
    display: none;
}
.centerdiv{
    border-radius: 15px;
background: #D8E3F6;
padding: 15px 20px;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 40px;
.dropdown{
    outline: none;
    box-shadow: none;
    outline: none;
    background: none;

    .dropdown-toggle::after{
        display: none;
    }
    .btn{
        background: transparent;
        border: none;
        outline: none;
        padding: 0px;
        margin: 0px;
        color: #0E1120;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        box-shadow: none;
        &:active{
            outline: none;
            box-shadow: none;
            outline: none;
            background: none;
            color: #0E1120;
        }
        &:focus{
            outline: none;
            box-shadow: none !important;
            outline: none;
            background: none;
            color: #0E1120;
        }
    }
}
.para{
    color: #0E1120;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 7px;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%;
}
}
.tomipay-wrapper {
    .dfgtryrthvbdfg {
        display: block;
    }

    .yujngjmmy {
        display: none;
    }

    .dfghsfdsergcv {
        margin-left: 0px !important;
    }
}

// .dropdown-toggle{
//     .dropdown-menu{

//     }
// }

.navbar {
    position: relative;
    // border-radius: 120px;
    // background: linear-gradient(90deg, rgba(246, 132, 191, 0.10) 0%, rgba(132, 27, 81, 0.10) 100%);
    // backdrop-filter: blur(10px);
    // display: flex;
    // padding: 13px 60px !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 31px;

    .ahsbahsba {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 0px;
        transform: translateX(-50%);

        img {
            transition: transform 0.6s ease 0s;
            width: 146px;
            transform: scale(1) translateY(92px);
        }
    }
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a:hover {
    color: #000;
    border-radius: 8px;
}

.ashvshsv {
    text-decoration: none !important;
    border: none !important;
}

.signxs {

    font-style: normal;
    // font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FF0083;
    background-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    border: none !important;
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a {
    border-radius: 8px;
}

.button-hedaer {
    transition: .4s ease-in-out;
    background: #FF0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    width: 139px;
    height: 48px;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #FF0083;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    cursor: pointer;
    margin-left: 41px;

    &:hover {
        background-color: transparent;
        border: 1px solid #FF0083;
        color: #FF0083;
    }
}

.main-navbar {
    // transition: .4s ease-in-out;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 106px;
    border-bottom: 1px solid transparent;
    z-index: 100;
    padding-left: 0px;
    transition: height 0.2s ease 0s;
    background: transparent !important;
    width: 100%;

    .navbar {
        // padding: 30px 0px 20px 0px;
        justify-content: space-between;
    }

    .navbar-nav {
        .nav-link {
            transition: .4s ease-in-out;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            // border: none;
            padding: 0.5rem 0px;
            color: #9D9D9D;
            margin-right: 18px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            outline: none;
            // &:hover {
            //     
            //     font-style: normal;
            //     color: #000000;
            //     cursor: pointer;
            //     font-size: 16px;
            //     line-height: 110%;
            //     border-bottom: 2px solid #FF0083;
            // }
        }

        li {
            .nav-links {
                transition: .4s ease-in-out;
                display: block;

                font-style: normal;
                cursor: pointer;
                text-decoration: none !important;
                // color: #000000;
                font-size: 16px;
                line-height: 110%;
                // border-bottom: 2px solid #FF0083;
                margin-right: 18px;
                color: #9D9D9D !important;
            }
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FF0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.togg i {
    color: #000000 !important;
}

.sduyfgsyfugsfygfiofdsu {
    display: flex;
    justify-content: start;
    align-items: center;
}

// .main-navbar .nav-item {
//     margin-bottom: 8px !important;
// }

// .navbar-expand-xl .navbar-collapse{
//     display: flex!important;
//     justify-content: space-between;
//     align-items: center;

// }

.connectmodal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
max-width: 500px;
        .modal-content {
            border-radius: 15px;
background: var(--frame-color, #E7F2FF);
backdrop-filter: blur(92px);
      
            padding: 30px;
            // min-width: 608px;
            width: 100%;
            margin: 0 auto;

            .modal-body {
          padding: 0;

                .modalhead {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
margin-bottom: 25px;
                    .modalheading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: var(--primary-color, #0E1120);
                        text-align: start;
                        width: 100%;
                    


                    }

                    .btnclose {
                        position: absolute;
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        top: 0px;
                        right: 30px;
                    }
                }

                .connectwalletdiv {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    justify-content: space-between;

                    .wallet {
                        border-radius: 15px;
background: #E0ECFA;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding:15px;
                        cursor: pointer;
                        width: 100%;
                        &:hover{
                            background: #D0DEEF;
                        }

                        .walletimg {
                            width: 80px;
                            height: 80px;
                            margin-bottom: 5px;
                        }

                        .walletname {

                            font-style: normal;
                            font-size: 16px;
                            font-weight: 500px;
                            line-height: 24px;
                            text-align: center;
                            color: #311E1A;
                        }
                    }
                }

            }
        }
    }
}

.main-navbar-mobile {
    .disconnectbtn {
        float: right;

        button {
            background: transparent;
            border-radius: 5px;
            padding: 14px 25px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            border: 1px solid #FF0083;
            text-transform: uppercase;
            color: #FF0083;
            // border: none;
        }
    }

    transition: .4s ease-in-out;
    z-index: 100;
    width: 100%;
    height: 106px;
    position: fixed;
    transition: height 0.2s ease 0s;
    border-bottom: 1px solid #424242;

    .navbar-nav .dropdown .dropdown-menu li a {
        border-radius: 8px;
    }

    .ahsbahsba {
        img {
            transform: scale(0.8) translateY(45px);
        }
    }

    background: #0d0d0d;

    .navbar {
        padding: 30px 0px 20px 0px;
        justify-content: space-between;
    }

    // .navbar .ahsbahsba{
    //     top: 54%;
    // }
    // .nav-item {
    //     margin-bottom: 8px !important;
    // }

    .navbar-nav {
        .nav-link {
            transition: .4s ease-in-out;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            padding: 0.5rem 0px;
            color: #9D9D9D;
            margin-right: 18px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            // &:hover {
            //     
            //     font-style: normal;
            //     color: #000000;
            //     font-size: 16px;
            //     line-height: 110%;
            //     border-bottom: 2px solid #FF0083;
            // }
        }

        .nav-links {
            transition: .4s ease-in-out;
            display: block;

            font-style: normal;
            cursor: pointer;
            text-decoration: none !important;
            // color: #000000;
            font-size: 16px;
            line-height: 110%;
            // border-bottom: 2px solid #FF0083;
            margin-right: 18px;
            color: #9D9D9D !important;
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FF0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.cvvcvcvcvcvv {
    width: 38px;
}

.navbar-toggler {
    // outline: none !important;
    // .togg {
    //     i {
    //         color: #FF0083 !important;
    //     }
    // }
}

.nav-item {
    cursor: pointer;
}

.modalsecurity .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.modalsecurity .PrivateNotchedOutline-root-1 {
    padding-left: 8px;
    border: 1.5px solid #EAEAEA;
    border-radius: 10px;
}

.modalsecurity .ForInput input {
    padding: 14px 20px !important;
    background: #FFFFFF;
    border: 1.5px solid #EAEAEA;
    border-radius: 10px;

    &::placeholder {

        font-style: normal;
        font-size: 16px;
        line-height: 130%;
        color: #a3a1a1;
    }
}

.sdhfvshgdfvsdhj {
    background: transparent;
    border-radius: 4px;
    border: 1px solid #ff0083;
    width: 210px;
    // margin-left: 12.5px;
    height: 44px;
    margin-right: 20px;
    color: #ff0083;
    display: flex;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-size: 18px;
    line-height: 100%;
    cursor: pointer;

    &:hover {
        background: #ff0083;
        border: 1px solid #ff0083;
        color: #ffffff !important;
    }
}

.ajnbdsajdbnsj {
    display: flex;
    justify-content: end;
    align-items: center;

    .kk {
        i {
            font-size: 28px;
            margin-right: 16px;
            color: #FF0083;
        }
    }

    .LL {
        i {
            font-size: 28px;
            color: #FF0083;
        }
    }
}

.modalsecurity .MuiOutlinedInput-input {
    border: 1px solid rgb(190, 186, 186);
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.modalsecurity .input_content {
    position: relative;

    input {
        left: 0px;
        top: 0px;

        background: #ffffff;
        border: 1.5px solid #eaeaea;
        border-radius: 10px;
    }

    img {
        position: absolute;
        right: 12px;
        top: 27.5%;
    }
}

.modalsecurity {
    .feilddd {
        position: relative;
    }

    .xvshvsx {
        position: absolute;
        right: 16px;
        top: 29px;
    }

    .dghvgdv {
        color: #FF0083 !important;
        padding-bottom: 20px;
    }

    .dcdcdc {
        width: 70%;
    }

    .modal-content {
        text-align: center;
        padding: 10px;
        background: #FFFFFF;
        border-radius: 15px;

        .modal-body {
            .main-headersdsd {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .shvdshvd {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 110%;
                }

                button.close {
                    background-color: transparent;
                    -webkit-appearance: none;
                    position: absolute;
                    border: 1.5px solid #C8C8C8;
                    border-radius: 40px;
                    // padding: 8px;
                    cursor: pointer;
                    left: 86.33%;
                    top: -23.67%;
                    width: 40px;
                    height: 40px;
                    padding: inherit;
                }

                .bdjbsdjc {
                    // margin: 40px;
                }

                .sgdvsgdvgsvd {
                    align-items: center;
                    /* padding: 18px 40px; */
                    gap: 10px;
                    width: 100%;
                    height: 54px;
                    background: #FF0083;
                    border-radius: 10px;
                    margin: auto;
                    border: none;

                    font-style: normal;
                    // font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;

                    &:hover {
                        color: #FF0083;
                        border-radius: 4px;
                        border: 2px solid;
                        background-color: #fff;
                        border-radius: 10px;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                button.btn.btn-common.btn-lg.btn-block {
                    align-items: center;
                    /* padding: 18px 40px; */
                    gap: 10px;
                    height: 52px;
                    background: #FF0083;
                    border-radius: 10px;
                    margin: auto;

                    font-style: normal;
                    // font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;

                    &:hover {
                        color: #FF0083;
                        border-radius: 4px;
                        border: 2px solid;
                        background-color: #fff;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.cvcvcvcv {
    margin-left: 14px;
}

.sdvgdvsdhvd {
    display: none;
}

.main-navbar.fgfgfgfgfgf {
    .navbar-toggler-icon {
        color: #FF0083 !important;
    }
}

.main-navbar-mobile.fgfgfgfgfgf {
    .navbar-toggler-icon {
        color: #FF0083 !important;
    }
}

// @media (max-width: 6000px) {

// }

// @media (max-width: 2560px) {
//     .sdvgdvsdhvd {
//         display: none;
//     }
// }

@media (max-width: 1500px) {
    // .main-navbar .navbar-nav .nav-link{
    //     margin-right: 12px;
    // }
}

@media (max-width: 1300px) {
    .main-navbar .navbar-nav .nav-link {
        margin-right: 23px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        margin-right: 23px;
    }

    .main-navbar .navbar-nav .nav-links {
        margin-right: 23px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        margin-right: 23px;
    }
}

@media (max-width: 1199px) {

    .navbar-toggler-icon {}

    .sdvgdvsdhvd {
        display: none !important;
    }

    .mobile-sidebar .offcanvas-body {
        z-index: -1;
    }

    .gcsgcdgsdvcgc {
        width: 112px;
    }

    .main-navbar .navbar-nav li a.active {
        margin-right: 0px !important;
    }

    .main-navbar-mobile .navbar-nav .nav-link.active {
        margin-right: 0px !important;
    }

    .navbar-collapse {
        box-shadow: 2px 7px 13px rgba(0, 0, 0, 0.09) !important;
    }

    .main-navbar-mobile .navbar-nav .nav-link:hover {
        border: none !important;
    }

    .navbar-collapse {
        background-color: #fAFAFA;
        border-radius: 18px;
        padding-bottom: 30px;
    }

    .main-navbar {
        height: unset;
    }

    .main-navbar .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        padding-bottom: 15px !important;
    }

    .sdvgdvsdhvd {
        display: block;
    }

    .navbar .ahsbahsba {
        display: none;
    }

    .button-hedaer {
        margin-left: 0px;
        margin-top: 20px;
    }

    .shdvs {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .main-navbar .sbvsx {
        display: flex;
        margin: 0 auto;
    }

    .main-navbar .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbar {
        .form-inline {
            justify-content: center;
        }
    }
}

.main-navbar-mobile {
    .collapse.show {
        // display: none!important;
    }
}

.main-navbar .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    font-size: 27px;
    line-height: 12px;
    background: no-repeat center center;
    background-size: 100% 100%;
    color: #ff0083 !important;
    transform: rotate(90deg);
}

.main-navbar-mobile .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    font-size: 27px;
    line-height: 12px;
    background: no-repeat center center;
    background-size: 100% 100%;
    color: #ff0083 !important;
    transform: rotate(90deg);
}

@media (max-width: 991px) {
    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobile .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobile .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar {
        .formm-mm {
            .input-bbb {
                margin-left: 0px;
            }
        }

        .form-inline {
            justify-content: center;
        }
    }

    .main-navbar .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        margin: 0 !important;
        padding-bottom: 15px !important;
    }

    .main-navbar .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbar .navbar-nav .dropdown .dropdown-menu {
        position: unset;
    }
}

.main-navbar .nav-item {
    position: relative;
}

.main-navbar-mobile.mmmkkkmmkk .sdvgdvsdhvd {
    margin-right: 30px;
}

.main-navbar-mobile .nav-item {
    position: relative;
}

.hide {
    display: none;
    background-color: #FF0083;
    color: #FAFAFA;
    font-size: 12px;
    font-weight: 600;
    padding: 8px;
    border-radius: 10px;
    position: absolute;
    top: 50px;
    left: 0;
    white-space: nowrap;

}

.mmmkkkmmkk .gcsgcdgsdvcgc {
    width: 100% !important;
}

.main-navbar-mobile {
    .gcsgcdgsdvcgc {
        width: 77%;
    }
}

.onhover-show:hover .hide {
    display: block !important;
}

@media (max-width: 600px) {
    .navbar{
        margin-top: 12px !important;
    }
    .centerdiv{
        padding: 15px;
        gap: 20px;
        display: none;
    }
    .navbar{
        border-radius: 45px;
    }
    .mmmkkkmmkk .gcsgcdgsdvcgc {
        margin-left: 8px !important;
        width: 107.101px;
height: 26px;
flex-shrink: 0;
    }

    .main-navbar.dfdfdfdfdfvfvrve.mmmkkkmmkk.fgfgfgfgfgf .navbar {
        padding: 10px !important;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

    }

    .main-navbar-mobile.dfdfdfdfdfvfvrve.mmmkkkmmkk.fgfgfgfgfgf.fgfggfhfghfgh .navbar {
        padding: 20px !important;
        // height: unset;

    }

    .main-navbar-mobile.dfdfdfdfdfvfvrve.mmmkkkmmkk.fgfgfgfgfgf.fgfggfhfghfgh {
        height: unset !important;
    }



    .main-navbar-mobile .sdvgdvsdhvd {
        display: block !important;
    }

    // .hhjjjhhjjj{
    //     display: none!important;
    // }
    .hide {
        top: 50px;
        left: 50%;
        transform: translate(-50px, 0px);
        z-index: 11111;
    }

    .main-navbar .navbar-nav li .nav-links {
        margin-right: 0px;
    }


    .hbdsjbd {
        width: 167px;
    }

    .modal-body .main_text .bhvchvh {
        font-size: 43px !important;
    }
}

.buttonsss {
    background-color: transparent;
    border: none;
}

@media (max-width: 400px) {
    .navbar-brand {
        img {
            width: 167px;
        }
    }
}

@media (max-width: 400px) {
    .modal-body .main_text .bhvchvh {
        font-size: 30px !important;
    }
}

@media (max-width: 350px) {
    .navbar-brand {
        img {
            width: 150px;
        }
    }
}

.main-navbar-mobile.fgfggfhfghfgh .navbar-nav .nav-link {
    margin-right: 16px;
}

.sbjdfbsj {
    display: none;
}

.main-navbar-mobile .sdvgdvsdhvd {
    display: block;
}

.main-navbar-mobile .navbar .ahsbahsba {
    display: none;
}

.main-navbar-mobile .sdvgdvsdhvd {
    margin-right: 0px;
}

.bnbnbnnbnb {
    position: relative;

    .dcdcdcddf {
        position: absolute;
        right: 0;
        top: 18px;
    }
}





// abdullah code sgtart here

.Item_item__2ODOE {
    padding: 0px !important;
}

.Submenu_left__24A4d {
    left: 100% !important;
}

.Item_item__2ODOE:hover>div {
    flex-direction: row !important;
}

.Item_item__2ODOE:hover {
    background-color: #FBFBFB !important;
    border-radius: 7px;
}

.firstitemas .headingwehave {
    h3 {

        font-style: normal;
        font-weight: 600;
        font-size: 18px !important;
        line-height: 110%;
        color: #2E3433;
    }
}

.Dropdown_dropdown__3MOKt .Dropdown_menu__1r9EX ul {
    width: 100% !important;
}

.Submenu_submenu__1oYvO.Submenu_left__24A4d {
    left: 100% !important;
    width: 345px;
    padding: 15px;
    background: #FFFFFF;
    border-left: 1px solid #F4F4F4;
    border-radius: 0px 10px 10px 0px;
    box-shadow: 0px 18px 40px -10px rgba(0, 0, 0, 0.04);
}

.Submenu_left__24A4d {
    top: 0px !important;
}

.outerdivs {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 10px !important;
    padding-left: 0px !important;


    .leftdside {
        margin-right: 10px;
        width: 50px;

        img {
            width: 50px;
        }
    }

    .rightdside {
        h4 {

            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 110%;
            color: #fff;
            margin-bottom: 6px;
        }

        h5 {

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 110%;
            color: #545454;

        }
    }
}

.Dropdown_dropdown__3MOKt .Dropdown_menu__1r9EX {
    width: 345px;
    background: #FFFFFF !important;
    box-shadow: 0px 18px 40px -10px rgba(0, 0, 0, 0.04) !important;
    border-radius: 10px !important;
}

.firstitemas {
    &:hover {
        background-color: transparent !important;
    }

    padding: 10px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
}

.Dropdown_dropdown__3MOKt .Dropdown_menu-left__1pA_o {
    padding: 15px;
    padding-right: 0px !important;
}

.Dropdown_dropdown__3MOKt .Dropdown_menu-left__1pA_o {
    left: 0 !important;
}

.onhover-show {
    margin-right: 1px;
}

.Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ.Dropdown_button-secondary__1s6lF:focus {
    box-shadow: none !important;
    color: #000000 !important;
}

.Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    background-color: transparent !important;
    padding: 0px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 110% !important;
    color: #9D9D9D !important;
    margin-right: 10px !important;
}

.outerdivs .rightdside.nhnjnjjnnjn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.outerdivs {
    width: 100%;
}

.dropps .Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    width: 100px !important;
}

.dropps .dcdcdcddf {
    right: 9px !important;
    pointer-events: none;
}

.droppss .Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    width: 75px !important;
}

.droppss .dcdcdcddf {
    right: 9px !important;
    pointer-events: none;
}

.droppsss .Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    width: 128px !important;
}

.droppsss .dcdcdcddf {
    right: 9px !important;
    pointer-events: none;
}

.droppssss .Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    width: 122px !important;
}

.droppssss .dcdcdcddf {
    right: 9px !important;
    pointer-events: none;
}

.droppsssss .Dropdown_dropdown__3MOKt .Dropdown_button__21GsJ {
    width: 80px !important;
}

.droppsssss .dcdcdcddf {
    right: 9px !important;
    pointer-events: none;
}

//  abdullah code end here

// hamza mobile side bar scss here..........................
.mobile-sidebar {
    background: #FAFAFA;
    width: 100% !important;

    .offcanvas-header {
        padding: 23px 20px !important;
        background: #FAFAFA;
        border-bottom: 1px solid #F1F1F1;

        .btn-close {
            background: url("../../../Assests/offcanvasclose.svg");
            width: 24px;
            height: 24px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 29px;
            right: 20px;
            padding: 0px;
            margin: 0px;

            &:focus {
                box-shadow: none !important;
            }
        }
    }

    .offcanvas-body {
        padding: 0px;
        position: relative;

        .accordion {
            .accordion-item {
                background-color: transparent;
                border: none;
                border-radius: none;

                .accordion-header {

                    .accordion-button {
                        padding: 18px 20px;
                        background: #FAFAFA !important;
                        border-bottom: 1px solid #F1F1F1 !important;

                        font-style: normal;
                        font-size: 16px;
                        line-height: 110%;
                        color: #000000;
                        border-radius: none !important;
                        box-shadow: none !important;

                        &:focus {
                            box-shadow: none !important;
                            border-color: #F1F1F1 !important;
                        }

                        &::after {
                            background: url("../../../Assests/downaccordionimg.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .accordion-body {
                    padding: 0px;
                    position: relative;
                    z-index: 99;

                    .sidebarinner {
                        .links {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding: 18px 35px;
                            background: #F7F7F7;
                            border-bottom: 1px solid #F1F1F1;
                            text-decoration: none;

                            font-style: normal;
                            font-size: 16px;
                            line-height: 110%;
                            color: #000000;
                        }
                    }
                }
            }
        }

        .sidebarfooter {
            position: absolute;
            bottom: 7px;
            width: 100%;
            z-index: -99999;

            .socialicons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                margin-bottom: 19px;
            }

            .foottextside {

                font-style: normal;
                font-size: 14px;
                line-height: 17px;
                color: #A5A5A5;
                text-align: center;
                margin-bottom: 8px;
            }
        }
    }

    .disconnectbtn {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            background: transparent;
            border-radius: 5px;
            padding: 14px 25px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            border: 1px solid #FF0083;
            text-transform: uppercase;
            color: #FF0083;
            // border: none;
        }
    }
}

// enddddddddddddddddddddddddddd............................

.foottextside {
    a {
        text-decoration: none !important;
    }
}

// New Css

.dropdownnn {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100px;
    background-color: #0abf53;
}

.dropdownnn li {
    position: relative;
}

.dropdownnn li a {
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 10px;
}

.dropdownnn li ul {
    position: absolute;
    top: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    line-height: normal;
    background-color: #333;
}

.dropdownhover .Dropdown_dropdown__3MOKt .Dropdown_menu-left__1pA_o {
    display: block !important;
}

.dropdownnothover .Dropdown_dropdown__3MOKt .Dropdown_menu-left__1pA_o {
    display: none !important;
}

.dropdownnn li ul li a {
    text-align: left;
    color: #cccccc;
    font-size: 14px;
    padding: 10px;
    display: block;
    white-space: nowrap;
}

.dropdownnn li ul li a:hover {
    background-color: #0abf53;
    color: #ffffff;
}

.dropdownnn li ul li ul {
    left: 100%;
    top: 0;
}

ul li:hover>a {
    background-color: #0abf53;
    color: #ffffff !important;
}

ul li:hover>ul {
    display: block;
}


.asdasdadadmultilevel {}

.dropdownnn {
    background-color: transparent;

    .asdasdadadmultilevel {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.dropdownnn li:hover .asdasdadadmultilevel {
    background-color: transparent !important;
    color: #000 !important;
}

.dropdownnn li:hover .asdasdadadmultilevel p {
    transition: .4s ease-in-out;
    display: block;

    font-style: normal;
    cursor: pointer;
    text-decoration: none !important;
    font-size: 16px;
    line-height: 110%;
    margin-right: 10px;
    color: #9D9D9D !important;
}

.asdasdadadmultilevel p {
    transition: .4s ease-in-out;
    display: block;

    font-style: normal;
    cursor: pointer;
    text-decoration: none !important;
    font-size: 16px;
    line-height: 110%;
    margin-right: 10px;
    color: #9D9D9D !important;
}

.main-upperdivs {
    width: 345px;
    background: #0d0d0d !important;
    box-shadow: 0px 18px 40px -10px rgba(0, 0, 0, 0.04) !important;
    border-radius: 10px !important;
    padding-bottom: 10px !important;
}

.main-upperdivs .headingwehave h3 {

    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 110%;
    color: #fff;
}

.main-upperdivs .headingwehave {
    padding: 10px !important;
    padding-top: 24px !important;
    padding-right: 0px !important;
    padding-left: 26px !important;
    padding-bottom: 20px !important;
}

.outerdivs .rightdside.nhnjnjjnnjn {
    width: 233px !important;
}

.asduyasdgasuydga {
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 8px;
}

.sdsdsdsffgdsds {
    margin-right: 30px !important;
    margin-top: -11px !important;
}

.asduyasdgasuydgaaa {
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 8px;
}

.main-navbar-mobile .hhjjjhhjjj {
    display: none;
}

.asduyasdgasuydga:hover .ausdgbayudgaiudgiasudh {
    background: #181818;
    border-radius: 7px;
}

// .mmmkkkmmkk {
//     background: linear-gradient(270deg, #CFD9E0 0%, #F2FAFF 47.92%, #CFD9E0 98.44%) !important;

// }

.hhjjjhhjjj {
    margin-right: 30px !important;
    margin-top: -5px !important;
}

.asduyasdgasuydgaaa:hover .ausdgbayudgaiudgiasudhaa {
    background: #181818;
    border-radius: 7px;
}

// .mimimmimimimi{
//     border-top-left-radius: 0px!important;
//     border-bottom-left-radius: 0px!important;
// }
.foottextside a {
    color: #A5A5A5 !important;
}

.lllkkklll {
    margin-right: 12px;
}

.lllkkkllll {
    margin-right: 30px;
}

.lllkkklllll {
    margin-right: 10px;
}

.dropdownnn li a {
    // padding: 0px!important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}


// .asduyasdgasuydga{
//     position: relative;
//     h6{
//         position: absolute;
//         display: none;
//     }
//     &:hover h6{
//         display: block;
//         width: 91%;
//         height: 100%;
//         border-radius: 10px;
//         background: #ff0083!important;
//         opacity: 0.8;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         color: #ffffff;
//         font-size: 18px;
//     }
// }

.asduyasdgasuydga {
    position: relative;

    h6 {
        position: absolute;
        right: 25px;
        top: 26px;
    }
}

.outermainhover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 234px;

    h6 {
        color: #ff0083;
        font-size: 14px;
        display: none;
    }

    &:hover h6 {
        display: block;
    }
}

.asduyasdgasuydga:hover .outermainhover h6 {
    display: block;
}


.main-navbar.dfdfdfdfdfvfvrve.fgfgfgfgfgf .navbar-toggler-icon {
    color: #ffffff !important;
}




@media (max-width:991px) {
    .connectmodal .modal-dialog .modal-content .modal-body .connectwalletdiv .MOBIKEDEISGNSS {
        display: none !important;
    }

    .connectmodal .modal-dialog .modal-content .modal-body .connectwalletdiv {
        justify-content: center;
    }
}

.sduyfgsyfugsfygfiofdsu:hover a {
    background-color: transparent !important;

}


@media (max-width:650px) {
    .MOBIKEDEISGNSS {
        display: none !important;
    }


    .connectmodal .modal-dialog .modal-content {
        min-width: 100%;
        padding: 20px;
    }

    .connectmodal .modal-dialog .modal-content .modal-body {
        padding: 25px 20px;
        padding-bottom: 58px;
    }

    .connectmodal .modal-dialog .modal-content .modal-body .modalhead .modalheading {
        text-align: start;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 41px;
    }

    .connectmodal .modal-dialog .modal-content .modal-body .modalhead .btnclose .cross_btns {
        width: 18px;
        height: 18px;
    }

    .connectmodal .modal-dialog .modal-content .modal-body .modalhead .btnclose {
        top: 21px;
        right: 20px;
    }

    .connectmodal .modal-dialog .modal-content .modal-body .connectwalletdiv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-logo {
        display: block !important;
        width: 95px;
    }

    .main-navbar .navbar {
        justify-content: space-between !important;
    }

    .disconnectbtn {
        text-align: center;
    }

    .main-navbar .disconnectbtn button {
        padding: 16px 24px !important;
        width: 110px;
        white-space: nowrap;
        height: 46px;
        font-size: 14px !important;
    }

    .metamask-btnyscycyc {
        display: none !important;
    }
}

.main-navbar .navbar-nav .nav-link:hover {
    color: #9D9D9D !important;
}

.sduyfgsyfugsfygfiofdsu:hover .nav-link {
    color: #9D9D9D !important;
}

.sduyfgsyfugsfygfiofdsu:hover {
    color: #9D9D9D !important;

}

.abb {
    border-radius: 50% !important;
}