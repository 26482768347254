.newbanner {
    // height: 550px;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    // margin-bottom: 163px;
    padding-top: 177px;

    .bannerbgimg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }



    .explorepara {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: 3px;

        .greyclr {
            color: #0E1120;


            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin-bottom: 8px;
            z-index: 9;
            position: relative;
            opacity: 0.5;
        }

        .pinkclr {
            color: var(--Accent, #FF0083);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            position: relative;
            z-index: 9;
        }
    }

    .unlockhead {
        color: #0E1120;
        text-align: start;
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        // margin: 0 auto 8px;
        max-width: 700px;
        width: 100%;
        z-index: 9;
        position: relative;

        .pinkclr {
            color: #0E1120;
            font-size: 80px;
            font-style: normal;
            font-weight: 800;
            line-height: 110%;
        }
    }

    .usepara {
        color: #67717D;
        text-align: start;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 30px;
        z-index: 9;
        position: relative;
    }

    .pinkbtn {
        display: flex;
        width: 288px;
        padding: 20px 50px;
        justify-content: center;
        align-items: center;
        gap: 4px;

        border-radius: 15px;
        background: #222D37;
        color: #E8F2FF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: capitalize;
        gap: 8px;
        margin-top: 40px;
        // margin: 0 auto 25px;
        z-index: 9;
        position: relative;
    }

}

@media(max-width:992px) {
    .newbanner .unlockhead {
        font-size: 35px !important;
        padding-left: 15px;
        padding-right: 15px;

        .pinkclr {
            font-size: 35px !important;
        }
    }

    .newbanner {
        margin-bottom: 50px;
    }

    .newbanner .usepara {
        padding-left: 15px;
        padding-right: 15px;
    }

    .newbanner {
        height: 350px;
    }
}
@media(max-width:600px){
    .dotsss{
        display: block !important;
    }
    .newbanner {
        padding-top: 215px;
    }

    .bannerbgimg{
        display: none !important;
    }
    .mblimgbg{
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .newbanner{
        padding-top: 300px;
    }
    .newbanner .unlockhead{
        font-size: 50px !important;
    }
    .newbanner .usepara{
        font-size: 18px !important;
        max-width: 349px;
        margin-bottom: 31px;
    }
    .newbanner .pinkbtn {
        width: 243px;
        height: 56px;
        padding: 20px 0px;
        white-space: nowrap;
        margin: 0 auto;
    }
    .tokenstaking .main-heading .leftheading .lefthead{
        font-size: 40px !important;
    }
}
