.mainpower {
    // padding-bottom: 244px;
    padding: 80px 0px;
    position: relative;

    .mainpowerbg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 405px;
    }

    // .powerrightshade {
    //     position: absolute;
    //     top: 20px;
    //     right: -371px;
    //     max-width: 485.14px;
    //     width: 100%;
    //     height: 753.734px;
    //     border-radius: 753.734px;
    //     opacity: 0.1;
    //     background: var(--Accent, #FF0083);
    //     filter: blur(173.76002502441406px);
    //     transform: rotate(-30deg);
    // }

    // .powerleftshade {
    //     position: absolute;
    //     top: 20px;
    //     left: -244px;
    //     max-width: 485.14px;
    //     width: 100%;
    //     height: 753.734px;
    //     border-radius: 753.734px;
    //     opacity: 0.1;
    //     background: var(--Accent, #FF0083);
    //     filter: blur(173.76002502441406px);
    //     transform: rotate(-30deg);
    // }

    .powerhead {
        color: #0E1120;
        text-align: center;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        max-width: 746px;
        width: 100%;
        margin: 0 auto 36px;
        position: relative;
        z-index: 9;

        .pinkclr {
            color: var(--Accent, var(--White, #FF0083));
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }

    .powerpara {
        color: #67717D;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        max-width: 508px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 99999;
    }
}

@media(max-width:992px){
    .mainpower{
        padding-bottom: 70px;
    }
    .mainpower .powerhead{
        font-size: 40px;
        .pinkclr{
            font-size: 40px;
        }
    }

}

@media(max-width:600px){
    .mainpowerbg{
        display: none;
    }
    .mainpower .powerhead{
        font-size: 26px !important;
        margin-top: 26px;
    }
    .mainpower .powerpara{
        font-size: 20px;
    }
    .mainbannerimg{
        display: block !important;
        .bgbgbg{
      position: absolute;
            top: 0 !important;
            right: 0 !important;
            width: 100%;
            height: 100%;
        }
    }
    .mainpower{
        height: 870px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
    }
}