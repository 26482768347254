.mainprivacypolicy {
    min-height: 100vh;
    overflow: hidden;
    padding-bottom: 30px;
    position: relative;

    .privcy {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 435px;
    }
    .privcyimage{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200px;
    }

    .privacypolicy {
        position: relative;

        .privacypolicyhead {
            color: #fff;
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            white-space: nowrap;
            position: absolute;
            top: 45px;
        }

        .topprivacypolicy {
            border-top: 1px solid #DEDEDE;
            border-bottom: 1px solid #DEDEDE;
            padding: 40px 0px;

            .marginbottomzero {
                margin-bottom: 0px !important;
            }

            .privacypolicytoppara {
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                margin-bottom: 30px;
            }

            .privacypolicytophead {
                color: #67717D;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin-bottom: 20px;
            }

            ol {
                padding-left: 20px;
                margin-bottom: 0px;

                .privacypolicytopheadlist {
                    color: #fff;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%;
                }
            }
        }

        .termsflex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            margin-bottom: 21px;

            .termsnumber {
                color: #0E1120;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
            }

            .termspara {
                color: #67717D;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .privacypolicymainhead {
            color: #0E1120;
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            width: 800px;
        }

        .marginbot {
            margin-bottom: 21px;
        }

        .privacypolicylowerlisthead {
            color: #0E1120;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin: 21px 0px;
        }

        ol {
            padding-left: 20px;
            margin-bottom: 0px;

            .privacypolicytopheadlist {
                color: #67717D;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                width: 800px;
            }
        }

        .privacypolicymainpara {
            color: #67717D;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            width: 800px;

            .pinklink {
                text-decoration: none;
                color: #0E1120;
            }
        }
    }
.forbackground{
    // background: #E8F2FF;
    padding: 40px 120px 70px 120px;
    position: relative;

}
    .upper-head {
        padding-top: 150px;
        margin-bottom: 40px;

        .upperheadingmain {
            h3 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                margin-bottom: 24px;

                span {
                    color: #67717D;

                    font-family: "Proto Mono";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }

                .pink {
                    color: #FF0083;

                    font-family: "Proto Mono";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
        }

        h1 {
            color: #0E1120;
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }
}

@media (max-width:992px) {
    .upper-head h1 {
        font-size: 32px !important;
        margin: 45px 0;
        text-align: start;
    }

    .mainprivacypolicy .privacypolicy .privacypolicyhead {
        position: unset;
        font-size: 32px !important;
        margin-bottom: 45px;
    }

    .mainprivacypolicy .privacypolicy {
        padding-top: 45px;
    }
}

@media (max-width:600px) {
    .mainprivacypolicy .privacypolicy .topprivacypolicy .privacypolicytoppara {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .topprivacypolicy .privacypolicytophead {
        font-size: 22px;
    }

    .mainprivacypolicy .privacypolicy .topprivacypolicy ol .privacypolicytopheadlist {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicymainhead {
        font-size: 26px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicymainpara {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .privacypolicylowerlisthead {
        font-size: 18px;
    }

    .mainprivacypolicy .privacypolicy ol .privacypolicytopheadlist {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .termsflex .termsnumber {
        font-size: 16px;
    }

    .mainprivacypolicy .privacypolicy .termsflex .termspara {
        font-size: 16px;
    }
}


@media (max-width:390px) {
    .mainprivacypolicy .upper-head h1 {
        font-size: 26px !important;
    }
}