.tokenstaking {
    // margin-bottom: 203px;
    position: relative;

    .leftimg {
        position: absolute;
        top: -50%;
        left: 0;
        pointer-events: none;
    }

    .btn-approve.dsfdfd {
        button {
            opacity: 0.2;
        }
    }

    position: relative;
    z-index: 99;

    .bg-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        max-height: 369px;
    }

    .main-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 0px !important;
        // margin-bottom: 139px !important;
        padding-bottom: 0px 0px 139px;

        .leftheading {
            // display: flex;
            flex-direction: column;
            align-items: flex-end;
            align-items: flex-end;
            gap: 10px;

            .lefthead {
                color: var(--Accent, #FF0083);
                font-size: 60px !important;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                white-space: nowrap;
            }

            .leftpara {

                color: #67717D;
                margin-top: 25px;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }

        .rightheading {
            // display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .righthead {
                color: var(--Accent, #FF0083);
                font-size: 60px !important;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }

            .rightpara {
                color: #67717D;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                text-align: end;
            }
        }
    }

    .mainexchanges {

        background: #E0EBFA;
        padding: 70px 0px;

        .exchangeshead {
            color: #0E1120;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 32px;
        }

        .innerexchanges {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 1130px;
            margin: 0 auto;
            width: 100%;
            gap: 32px;
            flex-wrap: wrap;

            .exchangeimg {
                width: 200px;
                height: 70px;
            }
        }
    }

    .stakemainhead {
        color: #0E1120;
        text-align: center;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 18px;
    }

    .stakemainpara {
        color: #67717D;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        max-width: 422px;
        width: 100%;
        margin: 0 auto 48px;
    }

    .mainstakecontent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        gap: 20px;
    }

    .left-content {
        // min-width: 546px;
        min-width: 487px;
        border-radius: 20px;
        border: 1px solid #DCE7F3;
        background: #E7F2FF;
        padding: 25px;

        .upper-head {
            padding-bottom: 20px;

            h3 {
                color: #0E1120;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }

        .time-select {
            padding: 0px;
            margin-bottom: 20px;
            width: 100%;
            gap: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .yysyvsyvyxs {
                background-color: transparent;
                border: none;
                color: #222D37;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: capitalize;
                white-space: nowrap;
                height: 51.684px;
                padding: 0px 0px;
                border-radius: 15px;
                background: #D0DEEF;
                flex: 1;
                width: 100%;
                &:hover{
                    color: #FFFFFF !important;
    background: #FF0083 !important;
                }

                &:focus {
                    box-shadow: none !important;
                }

                // &:active {
                //     background-color: transparent;
                //     border: none;
                //     border-bottom: 1px solid #1777E2 !important;
                // }
            }

            .active-btn {

                border: none;
                color: var(--Accent, #FFF);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                text-transform: capitalize;
                white-space: nowrap;
                height: 51.684px;
                padding: 0px 0px;
                border-radius: 15px;
                background: #222D37;
                flex: 1;
                width: 100%;

                &:focus {
                    box-shadow: none !important;
                }

            }



        }

        .option-field {
            padding: 0 0px;
            margin-bottom: 20px;

            .upper-area {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 6px;

                label {
                    color: #0E1120;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;

                    margin-bottom: 0px;
                }

                h6 {
                    color: #67717D;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;

                }
            }

            .input-field {
                position: relative;

                .tomiimg {
                    position: absolute;
                    left: 24px;
                    top: 13.5px;
                }

                input {
                    background: #DFEAFC;

                    border-radius: 15px;
                    // border: 1px solid #222D37;
                    border: none;
                    transition: 0.3s linear;
                    height: 60px;

                    width: 100%;
                    color: #9CA9BC;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    outline: none;
                    padding: 22px 15px;

                    &::placeholder {
                        color: #9CA9BC;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }

                    &:focus {
                        border: 1px solid #222D37;
                    }

                    // &:hover {
                    //     border: 1px solid #FF0083;
                    // }
                }

                button {
                    border-radius: 15px;
                    background: #222D37;

                    position: absolute;
                    top: 8.5px;
                    right: 10px;
                    transition: 0.3s linear;
                    display: flex;
                    padding: 13px 30px;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    color: var(--White, var(--White, #FFF));
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    border: none;
                    outline: none;
                    &:hover{
                        color: #FFFFFF !important;
    background: #FF0083 !important;
                    }
                }
            }
        }

        .slide-ranger {
            padding: 0 0px;

            .slider {
                -webkit-appearance: none;
                width: 100%;
                height: 10px;
                border-radius: 46px;
                background: var(--Background-secondary, rgba(255, 255, 255, 0.10));
                outline: none;
                opacity: 0.7;
                -webkit-transition: .2s;
                transition: opacity .2s;
            }

            .slider:hover {
                opacity: 1;
            }

            .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #FF0083;
                cursor: pointer;
            }

            .slider::-moz-range-thumb {
                width: 24px;
                height: 24px;
                background: #FF0083;
                cursor: pointer;
            }

            .inner-value {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    color: #67717D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }

        .bottom-text {
            margin-top: 20px;
            padding: 0 0px;

            .text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 7px;

                &:last-child {
                    margin-bottom: 0;
                }

                h6 {
                    color: #67717D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                }

                h5 {
                    color: #0E1120;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;

                    span {
                        color: #0E1120;
                        ;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                    }
                }
            }
        }

        .btn-approve {
            margin-top: 20px;
            margin-bottom: 0px;
            padding: 0 0px;

            button {
                border-radius: 15px;
                background: #D0DAE7;
                display: flex;
                padding: 10px 16px;
                justify-content: center;
                align-items: center;
                color: #A9B1BF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                outline: none;
                border: none;
                text-transform: capitalize;
                width: 100%;
                &:hover{
                    color: #222D37 !important;
    background: #BDCBDC  !important;
                }
            }
        }

        .bottom-progress {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                background: #FF0083;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                color: #FFFFFF;
            }
        }
    }

    .right-content {
        width: 100%;
        border-radius: 20px;
        border: 1px solid #DCE7F3;
        padding: 25px;
        background: #E7F2FF;
        // .gradientborder {
        //     position: relative;
        //     border-radius: 30px;
        //     /* Adjust based on your needs */
        //     background: linear-gradient(185deg, #FF0083 -8.28%, rgba(49, 49, 49, 0.00) 57.61%);
        //     width: 100%;
        //     padding: 1px;
        //     overflow: hidden;

        //     .innercontent {
        //         border-radius: 30px;
        //         background: linear-gradient(103deg, rgba(255, 160, 198, 0.001) 0%, rgba(126, 126, 126, 0.0) 110.97%), rgba(29, 29, 29, 2);
        //         backdrop-filter: blur(35.099998474121094px);
        //     }
        // }

        .upper-head {
            padding-bottom: 16px;

            h3 {
                color: #0E1120;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }
        }

        .bottom-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .itemss {
                // flex: 1 0 20%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: 20px;
                position: relative;

                h6 {
                    font-style: normal;
font-weight: 500px;
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 17px;
                    color: #67717D;
                    margin-bottom: 5px;
                }

                h5 {
                    font-style: normal;
font-weight: 500px;
                    font-size: 14px;
                    line-height: 19px;
                    color: #0E1120;
                    white-space: nowrap;

                    span {
                        font-style: normal;

                        font-size: 14px;
                        line-height: 17px;
                        color: #0E1120;

                        img {
                            margin-top: -5px;
                            display: none;
                        }
                    }

                }

                .hide-btn {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    color: #0E1120;
                    display: flex;
                    align-items: center;
                }

                a[aria-expanded="false"] .hid {
                    display: none;
                }

                a[aria-expanded="true"] .dt {
                    display: none;
                }

                a .jjbjbbjb {
                    transform: rotate(180deg);
                }

                a .jjbjbbjbb {
                    transform: rotate(0deg);
                }


                // .btn-disable {
                //     pointer-events: none;
                //     color: #303234 !important;
                //     background: #FF0083 !important;
                //     opacity: 0.3;
                //     border: 1px solid rgba(255, 0, 131, 0.2) !important;
                //     color: #fff !important;
                // }
            }
        }

        .bottom-content1 {
            background: transparent !important;
            // border: 2px solid #FF0083;
            border-radius: 0px;
            margin-top: 0px;
            border-radius: 15px;
            border: 1px solid #D0E3F7;
background: #E7F2FF;
margin-bottom: 6px;
        }

        .bottom-content2 {
            // background: #171819;
            // justify-content: space-evenly;
            justify-content: flex-start;
            gap: 25px;
            border-top: 1px solid #D0E3F7;

            background: #E0EBFA;
        }
    }

}

@media (max-width:600px) {
    .tokenstaking .main-heading h4 {
        font-size: 20px;
    }

    .tokenstaking .left-content .upper-head h6 {
        font-size: 16px;
    }

    .tokenstaking .right-content {
        margin-top: 25px;
        // margin-bottom: 100px;
    }

    .tokenstaking .right-content .bottom-content {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tokenstaking .right-content .bottom-content .itemss h6 {
        font-size: 12px;
    }

    .tokenstaking .right-content .bottom-content .itemss h5 {
        font-size: 14px;
    }

    .tokenstaking .right-content .bottom-content .itemss h5 span img {
        margin: 5 !important;
    }

    .tokenstaking .right-content .bottom-content .itemss {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px;
        flex: 0 1 40%;
    }

    .tokenstaking .right-content .bottom-content .itemss-sm {
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
    }

    .tokenstaking .right-content .bottom-content2 .btn-transparent {
        width: 100%;
    }

    .tokenstaking .right-content .bottom-content2 .itemss {
        flex: 1 1 49%;
    }

    .tokenstaking .btn-transparent {
        font-size: 14px;
        background-color: transparent;
    }

    .tokenstaking .bg-cover {
        height: 380px;
        object-fit: cover;
    }

    .tokenstaking .main-heading h5 {
        font-size: 22px;
    }

    .tokenstaking .main-heading h6 {
        font-size: 22px;
    }

    .endrewards {
        width: 100%;
    }

    .itemss.itemss-sm {
        border-top: 1px solid #1E1F20;
    }
}

.pagination-custom {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
        background: #141516;
        border: 1px solid #272727;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }

    .right {
        background: #141516;
        border: 1px solid #272727;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;

        &:hover {
            background: #FF0083;
        }
    }

    .inner-values {
        display: flex;
        justify-content: center;
        align-items: center;

        .spec {
            background: #FF0083;
            border: 1.5px solid #FF0083;
            border-radius: 8px;
            width: 24px;
            height: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .total {
            font-style: normal;

            font-size: 16px;
            line-height: 17px;
            color: #fff;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: #fff;
            margin: 0 10px;
        }
    }
}


.MuiSlider-rail {
    height: 3px !important;
    // border-radius: 46px !important;
    background: #D0E3F7 !important;
    padding: 0 !important;
}

.MuiSlider-track {
    height: 20px !important;
    border-radius: 46px !important;
    background: var(--Background-secondary, rgba(255, 255, 255, 0.10)) !important;
    padding: 0 !important;
}

.MuiSlider-thumb {
    width: 24px !important;
    height: 34px !important;
    margin-top: -7px !important;
    border-radius: 4px !important;
    background: var(--Accent, #FF0083) !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.MuiSlider-root {
    color: #FF0083 !important;
    padding: 0 !important;
}



.bidmodal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content {
            background: #010101;
            border-radius: 5px;
            padding: 30px;
            min-width: 608px;
            width: 100%;
            margin: 0 auto;

            .modal-body {
                padding: 25px;
                border: 1.5px solid #FF0083;
                border-radius: 10px;
                position: relative;

                .loadermain {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .loadertext {

                        font-style: normal;
                        font-size: 18px;
                        line-height: 100%;
                        text-transform: capitalize;
                        color: #FFFFFF;
                    }

                    .tick {
                        margin-top: 20px;
                        margin-bottom: 30px;
                        width: 118px;
                        height: 118px;
                    }

                    .loader {
                        width: 104px;
                        height: 104px;
                        margin-top: 20px;
                        margin-bottom: 30px;
                        animation: spin 2s linear infinite;
                    }

                    @keyframes spin {
                        0% {
                            transform: rotate(0deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }

                .modalhead {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .modalheading {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        width: 100%;
                        margin-bottom: 30px;

                    }

                    .btnclose {
                        position: absolute;
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        top: 12px;
                        right: 18px;
                    }
                }

                .common-btn {
                    width: 100%;
                    padding: 12px;
                    font-size: 16px;
                    line-height: 150%;
                    background-color: #FF0083 !important;
                    color: #FFFFFF;
                    border: none;
                    margin-top: 30px;
                }
            }
        }
    }
}

@media(max-width:992px) {
    .tokenstaking .main-heading {
        flex-direction: column;
        margin-bottom: 30px !important;
    }

    .tokenstaking .main-heading .leftheading {
        justify-content: center;
        align-items: center;
    }

    .tokenstaking .main-heading .rightheading {
        justify-content: center;
        align-items: center;
    }

    .tokenstaking .mainexchanges {
        padding-bottom: 70px;
    }

    .tokenstaking .stakemainhead {
        font-size: 40px;
    }

    .tokenstaking .mainstakecontent {
        flex-direction: column;
        gap: 70px;
    }

    .tokenstaking .left-content {
        min-width: 100%;
    }

    section.tokenstaking {
        margin-bottom: 70px;
    }
    .tokenstaking .main-heading .leftheading .leftpara{
        margin-top: 25px;
        font-size: 18px;
    }
}


@media (max-width:650px) {
    .bidmodal .modal-dialog .modal-content {
        min-width: 100%;
        padding: 20px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body {
        padding: 20px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .modalhead .modalheading {
        text-align: start;
        font-size: 16px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .modalhead .btnclose .cross_btns {
        width: 18px;
        height: 18px;
        top: 26px;
        right: 20px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .common-btn {
        padding: 10px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .loadermain .loadertext {
        font-size: 14px;
        line-height: 16px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .loadermain .loader {
        margin-top: 0px;
        width: 80px;
        height: 80px;
    }

    .bidmodal .modal-dialog .modal-content .modal-body .loadermain .tick {
        margin-top: 0px;
        width: 80px;
        height: 80px;
    }

    .tokenstaking .mainexchanges .innerexchanges .exchangeimg {
        width: 150px;
    }
}

.pioneer-content {
    .bottom-text {
        margin-bottom: 96px;

        .text {
            h5 {
                span {
                    font-style: normal;

                    font-size: 14px;
                    line-height: 17px;
                    color: #BEBEBE;
                }
            }
        }
    }

    .inner-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .box1 {
            background: #0D0D0D;
            border: 1px solid #FF0083;
            border-radius: 5px;
            padding: 30px;

            h6 {
                font-style: normal;

                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #FF0083;
                text-align: center;
                white-space: nowrap;
            }
        }

        .box2 {
            background: #0D0D0D;
            border: 1px solid #FFFFFF;
            border-radius: 5px;
            padding: 30px;

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: #F2F2F2;
                white-space: nowrap;
                text-align: center;
            }
        }
    }
}

@media (max-width:600px) {
    .circlesimg{
        height: unset !important;
    }
    .newsectionnewstyle .stakingggimgg{
        display: none;
    }
    .formobliepic{
        display: block !important;
        .mblmbl{
       
            position: absolute;
            bottom: 0;
            left: 0;
      
        }
    }

    .tokenstaking .main-heading .rightheading .rightpara{
        font-size: 18px;
        margin-top: 25px;
        text-align: start;
    }
    .forfirstbg {
        padding-bottom: 30px;
    }
    .tokenstaking .main-heading .rightheading .righthead{
        font-size: 40px !important;
    }
    .tokenstaking .mainexchanges{
        padding: 30px 0px;
    }
    .tokenstaking .mainexchanges .exchangeshead{
        font-size: 30px;
        width: 330px;
        text-align: center;
        display: flex
;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    section.tokenstaking{
        margin-top: -51px;
    }
    .tokenstaking .leftimg{
        display: none;
    }
    .assfaeedhtrnedg {
        display: none !important;
    }

    .pioneer-content .inner-box {
        flex-direction: column;
        gap: 15px;
    }

    .pioneer-content .inner-box .box1 {
        width: 100%;
    }

    .pioneer-content .inner-box .box2 {
        width: 100%;
    }
}


.active-box {
    background: transparent;
    // border-bottom: 1px solid #FF0083;
    border-radius: 0px;
}

.MuiSlider-valueLabel {
    background: #FF0083 !important;
    color: #FF0083 !important;
    border-radius: 5px !important;
    width: 16px !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staking11 {
    justify-content: center;
    align-items: center;
}

.tokenstaking .main-heading .sfdsfsfdsfsdf {

    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 34px !important;
}

.sfdsfsfdsfsdf111 {

    font-style: normal !important;
    font-weight: 200 !important;
    font-size: 26px !important;
    color: #ffffff;
}


.sdhgavschjBcasjkbf {
    padding: 100px 0px 120px 0px;

    .connectbtn {
        border-radius: 15px;
        background: #222D37;
        color: var(--White, var(--White, #FFF));
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        display: flex;
        height: 48px;
        padding: 20px 10px;
        align-items: center;
        justify-content: center;
        border: none;
        text-transform: capitalize;
        width: 254px;
        white-space: nowrap;
        margin: 25px auto 0;
        &:hover{
            color: #FFFFFF !important;
    background: #FF0083 !important;
        }
    }
}

.sdhgavschjBcasjkbf p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #67717D;
    padding: 0px 10px;
    max-width: 352px;
    width: 100%;
    margin: 0 auto;
}

.newsectionnewstyle {
    background: #E7F2FF;
    padding: 70px 0px;
    position: relative;
    .stakingggimgg{
        position: absolute;
        top: 0;
        right: 0 !important;
        left: auto;
        height: 475px;
    }

}

.MuiSlider-thumb {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
}

.forclr {
    color: #0E1120;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.bannerbgimg1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
}

.forfirstbg {
    background: url(../../../src/Assests/bgbgbg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.circlesimg{
    width: 100%;
    height: 822px;
}